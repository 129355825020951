@import-normalize;
@import url("Font/stylesheet.css");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

*,
html,
body {
  font-family: "Helvetica";
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  box-sizing: 0;
}

:root {
  --primary-color: #fff;
  --secondary-color: #ffbc00;
  --text-color: #003d59;
  --font-bold: "Helvetica bold";
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

h6 {
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
}

h3 {
  font-size: 35px;
  font-weight: bold;
}

h2 {
  font-size: 42px;
  font-weight: normal;
}

h1 {
  font-size: 92px;
  font-weight: bold;
}

hr {
  padding: 0;
  margin: 0;
  width: 75%;
  background-color: rgba(51, 95, 125, 100%);
}

.hide-show-pass {
  position: relative;
}

.hide-show-pass input {
  /* margin-bottom: 10px !important; */
}

.hide-show-pass i {
  cursor: pointer;
  position: absolute;
  top: 17px;
  left: auto;
  right: 15px;
  color: #fff;
}

.password-validate li {
  text-align: start;
  color: #d64841;
}

.requirement-text ul {
  width: -webkit-fill-available;
}

#password-modal,
#clock-out-property,
#clock-in-property,
#trackerModal {
  max-width: 40%;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  margin: 0 !important;
}

#password-modal .map-modal.upload-modal .modal-form,
#clock-out-property .map-modal.upload-modal .modal-form,
#clock-in-property .map-modal.upload-modal .modal-form,
#trackerModal .map-modal.upload-modal .modal-form {
  width: 100%;
}

#password-modal .map-modal .modal-form.login-form input,
#trackerModal .map-modal .modal-form.login-form input,
#clock-out-property .map-modal .modal-form.login-form select,
#clock-in-property .map-modal .modal-form.login-form select {
  margin: 0 auto;
}

#password-modal .map-modal .modal-form.login-form button.select,
#trackerModal .map-modal .modal-form.login-form button.select,
#clock-out-property .map-modal .modal-form.login-form button.select,
#clock-in-property .map-modal .modal-form.login-form button.select {
  margin: 20px auto;
}

#upload-out-modal {
  max-width: 50%;
  margin: 0 auto;
  /*  height: 600px; */
}

#type {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../public/images/ic_downarrow.png");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 50%;
}

.noData {
  position: relative;
  height: 10vh;
}

.noData h5 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.active-nav {
  font-family: Helvetica bold, sans-serif;
  font-weight: bold;
  position: relative;
  color: var(--secondary-color) !important;
}

.active-nav::after {
  z-index: -1;
  content: "";
  height: 48px;
  width: 48px;
  background: url(../public/images/round.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div[class^="col-"],
div[class*=" col-"] {
  padding: 0;
}

:focus-visible,
:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

:focus {
  /* border: none !important; */
  border-color: transparent !important;
  /* outline: none !important;
  box-shadow: none !important; */
}

.form-select:focus {
  box-shadow: none;
}

.nav-logo {
  height: 94px;
  width: auto;
}

.profile-pic .profile-body {
  height: auto;
  padding: 11px 7px 7px;
}

.profile-pic .profile-body .profile-item {
  display: flex;
  flex-direction: column;
}

.profile-pic .profile-body button {
  background: transparent;
  color: #003d59;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 0 0px;
}

.profile-pic .profile-body button:hover {
  color: #ffba01;
}

.notification-message .message {
  background-color: transparent;
  padding: 0;
  font-size: 13px;
}

.notification:before {
  left: 18px !important;
  margin-top: -15px !important;
  font-size: 21px !important;
}

.spinner {
  position: absolute;
  opacity: 1 !important;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
}

.spinner-border {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 80%;
  opacity: 1 !important;
  margin-top: -16px;
  margin-left: -16px;
  z-index: 1000;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.btn-property.pdf {
  padding: 0;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
  padding: 0;
}

/***** Header *****/
.main-header {
  background: var(--text-color);
}

.navbar {
  max-width: calc(100% - 50px);
  margin: 0 auto;
  z-index: 999;
}

.navbar-expand-lg .navbar-nav a {
  color: var(--text-color);
  font-weight: 600;
}

.navbar-expand-lg .navbar-nav {
  margin: 0 auto;
}

.navbar-light .navbar-toggler-icon {
  background-image: url(../public/images/line.png);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url(../public/images/menu.png);
}

.navbar-expand-lg .navbar-nav a {
  text-decoration: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 3px;
}

.main-header li.nav-item {
  padding: 0px 8px;
}

.profile {
  display: flex;
  align-items: center;
}

.select {
  cursor: pointer;
  position: relative;
  max-width: 200px;
  width: 200px;
  background-color: var(--primary-color);
  color: var(--text-color);
  font-size: 22px;
  border-radius: 5px;
  padding: 19px 0;
  text-align: center;
  text-decoration: none;
  margin-right: 30px;
}

.not-allowed {
  cursor: not-allowed;
  opacity: .5;
}

.navbar .select-btn .myInputRow {
  position: relative;
  background: white;
  background-color: var(--primary-color);
  border-radius: 5px;
  color: #003d59;
  color: var(--text-color);
  cursor: pointer;
  font-size: 22px;
  margin-right: 30px;
  text-align: center;
}

.navbar .select-btn .form-select {
  max-width: 200px;
  width: 200px;
  height: 60px;
  color: var(--text-color) !important;
  font-family: Helvetica bold, sans-serif;
  font-weight: bold;
  font-size: 22px;
  padding: 0 10px 0 0;
}

#Services {
  background-image: url("../public/images/drop-down.png");
  appearance: none;
  background-size: auto;
}

.select span img {
  width: 14px;
  height: 7px;
  margin-left: 8px;
}

.select span .plus {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
}

.profile-pic {
  cursor: pointer;
  width: 55px;
  height: 55px;
  border-radius: 50px;
  border: 2px solid #fff;
}

.profile-pic div {
  height: 100%;
}

.profile-pic img {
  /* border: 5px solid var(--secondary-color); */
  border-radius: 50px;
  width: 100%;
  height: 100%;
}

.head-profile {
  height: auto !important;
}

/***** Selcet *****/
.op-select .css-b62m3t-container {
  width: 100%;
  align-self: center;
}

.op-select input {
  opacity: 0 !important;
}

.op-select {
  min-width: 172px;
  width: auto;
  margin-right: 15px;
  cursor: pointer;
}

.op-select .css-1s2u09g-control,
.css-1pahdxg-control {
  height: 47px;
  border: none !important;
  outline: none !important;
  background-color: var(--text-color) !important;
  cursor: pointer;
}

.css-14el2xx-placeholder {
  color: var(--primary-color) !important;
  font-family: var(--font-bold);
  font-size: 19px;
  text-align: center;
  padding: 2.5px 0 0 0;
  margin: 0;
}

.op-select .css-qc6sy-singleValue {
  color: var(--primary-color) !important;
  font-family: var(--font-bold);
  font-size: 18px;
  text-align: center;
  padding: 2.5px 0 0 0;
  margin: 0;
}

.op-select .css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

.op-select .css-qc6sy-singleValue::before {
  background-image: url(../public/images/white-plus.png);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 29%;
  left: 7px;
  position: absolute;
  width: 9%;
  top: 1px;
}

.css-1s2u09g-control>div {
  text-align: center;
}

.css-14el2xx-placeholder::before {
  background-image: url(../public/images/color-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 29%;
  left: 7px;
  position: absolute;
  width: 9%;
  top: 1px;
}

.op-select .css-1okebmr-indicatorSeparator::before {
  /* background-image: url(../public/images/drop-down.png); */
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 14%;
  left: auto;
  position: absolute;
  width: 11%;
  top: 22px;
  right: 6px;
}

.op-select .css-319lph-ValueContainer {
  text-align: center;
  cursor: pointer;
  padding: 2px 0;
  padding: 0 15px;
}

.op-select .css-1pahdxg-control {
  box-shadow: none !important;
}

.op-select .css-1pahdxg-control:hover {
  border: none;
  outline: none;
  box-shadow: none;
}

/* .css-1pahdxg-control .css-1okebmr-indicatorSeparator::before{top: 17px !important;} */
.op-select .css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  opacity: 0 !important;
  padding: 0 !important;
  width: 0.1px;
}

/***** End Selcet *****/
/***** End Header *****/

/***** Home *****/
.home {
  position: relative;
  background-image: url(../public/images/bg.png);
  background-color: var(--text-color);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  overflow-x: hidden;
}

.home,
.employee-profile {
  height: 100vh;
  overflow-y: scroll;
  min-height: -webkit-fill-available;
}

.employee-profile::-webkit-scrollbar,
.home::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.home .main-header {
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 9999;
  border-bottom: 0.5px solid;
  border-color: #ffffff1c;
}

.home .main-header.whitenavbar {
  background-color: var(--primary-color);
}

.home .main-header.active-scroll {
  background-color: var(--primary-color);
}

.home.exception {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-container {
  padding: 0;
  /* max-width: 1400px; */
  max-width: 72.91%;
  width: 100%;
  margin: auto;
  padding: 120px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  /* height: 100vh; */
}

#home .row>.col-lg-6 {
  height: 394.5px;
}

.edit-row.logout-row {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.home-container .row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.home-container .row .col-6:first-child {
  padding-right: 50px;
}

.home-container .select {
  margin-top: 40px;
  max-width: 269px;
  width: 100%;
  font-size: 25px;
  font-family: Helvetica bold, sans-serif;
  box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
  background: var(--secondary-color);
}

.message {
  font-family: Helvetica bold, sans-serif;
  width: fit-content;
  color: var(--primary-color);
  padding: 24px 20px;
  background-color: rgba(60, 104, 135, 20%);
  font-size: 22px;
  border-radius: 5px;
  position: relative;
  letter-spacing: 2px;
}

h6.message::before {
  content: "";
  background-image: url(../public/images/round-line.png);
  position: absolute;
  width: 50%;
  display: block;
  height: 39%;
  background-size: contain;
  background-repeat: no-repeat;
  left: -81%;
}

.large-text {
  margin: 12px 0;
  font-family: Helvetica bold, sans-serif;
  color: var(--secondary-color);
  text-shadow: 1px 0.2px var(--primary-color);
  letter-spacing: 8px;
}

.medium-text {
  color: var(--primary-color);
  margin-bottom: 40px;
  letter-spacing: 2px;
}

.small-text {
  color: var(--primary-color);
  font-size: 25px;
  line-height: 48px;
  letter-spacing: 1px;
  margin: 0;
}

.small-text span {
  color: var(--secondary-color);
  font-weight: 700;
}

/***** End Home *****/
/***** Drop-down *****/
.profile-position {
  position: relative;
}

.edit-profile-body a:hover {
  color: #ffba01;
}

.profile-body {
  z-index: 999;
  position: absolute;
  top: calc(100% + 34px);
  left: auto;
  right: 0;
  width: 180px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 15px;
  box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
  border-radius: 5px;
  background: white;
  opacity: 0;
  transform: scale(0.9);
  transform-origin: 50% 50px;
  will-change: transform;
  animation: showUser 0.4s forwards;
}

.profile-body::before {
  content: "";
  position: absolute;
  left: 85%;
  top: -13px;
  width: 25px;
  height: 25px;
  transform: translateX(-50%) rotate(45deg);
  background: white;
  border-radius: 0px 0px 0px 0px;
}

.profile-item {
  text-align: center;
}

.profile-item a {
  color: var(--text-color);
  font-size: 17px;
  line-height: 23px;
  text-decoration: none;
}

.employee-name h3 {
  margin-top: 20px;
}

@keyframes showUser {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/***** End Drop-down *****/
/***** contact-wrapper *****/
.home.contact-wrapper {
  position: relative;
  background-image: url("/public/images/contact-bg.png");
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  overflow-x: clip;
}

.contact-wrapper .home-container {
  max-width: 1691px;
  margin: 35px auto 0;
  padding-bottom: 61px;
  justify-content: end;
}

.contact-wrapper .home-container .row {
  justify-content: flex-end;
  width: 100%;
}

.contact-wrapper .login-form.edit-row {
  /* max-width: 800px; */
  max-width: 93.3%;
  padding: 20px 49px 45px;
}

.contact-wrapper .edit-row h2 {
  font-size: 60px;
  font-family: var(--font-bold);
  line-height: 110px;
  color: var(--text-color);
}

.contact-wrapper .home-container .select {
  margin-top: 0;
}

/***** End contact-wrapper *****/
/***** register *****/
.edit-row {
  /* max-width: 600px; */
  background-color: rgba(255, 255, 255, 95%);
  border-radius: 10px;
  max-width: 600px;
  width: 85.1%;
}

.map-modal .modal-form {
  max-width: 600px;
  width: 100%;
  margin: 2rem auto 0;

}

.map-modal.upload-modal {
  top: 50%;
  transform: translateY(-50%);
}

.map-modal.upload-modal .modal-form {
  max-width: 100%;
  width: auto;
  margin: 2rem auto 0;
}

.modal-form form label span {
  color: var(--secondary-color);
  margin: 0 6px;
  font-weight: 600;
}

.map-modal .modal-form label {
  display: flex;
}

.map-modal .modal-form.login-form input,
.map-modal .modal-form.login-form select {
  padding: 15px 20px;
  margin-bottom: 25px;
  font-size: 15px;
  line-height: 17px;
  max-width: 360px;
  width: 100%;
  display: flex;
}

.map-modal .modal-form.login-form button.select {
  margin: 20px 0;
  max-width: 360px;
  width: 100%;
}

.map-modal .modal-form.login-form button.select.add-more {
  margin: 0px 0px 30px;
  max-width: 260px;
}

.map-modal .modal-form.login-form button.remove {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;
  padding: 0 10px;
  border: none;
  background-color: transparent;
}

.img-desc{
  display: none;
}
.img-desc,.img-description {
  text-align: center;
  white-space: nowrap;
  max-width: 200px !important;
  z-index: 99;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto !important;
}

.tab-pils {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #003d59;
}

.tab-pils .back-arrow {
  font-size: 22px;
  color: var(--text-color);
  position: absolute;
  width: 16px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.nav-pills .nav-link {
  color: var(--text-color) !important;
  font-size: 28px;
  font-family: helvetica bold;
  cursor: pointer;
  padding: 19px 0;
}

.nav-pills .nav-link.active {
  position: relative;
  border-width: 1px;
  background-color: transparent;
  color: var(--text-color) !important;
}

.nav-pills .nav-link.active:focus {
  border: none;
  outline: none;
  background: transparent;
  box-shadow: none;
}

.nav-pills .nav-link.active::after {
  content: "";
  position: absolute;
  width: 50%;
  bottom: 0;
  left: 22%;
  border-radius: 24px;
  border-bottom: 3px solid var(--text-color);
}

.tab-content {
  padding: 30px 50px 25px;
}

.contact-form {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.home .contact-form {
  display: block;
  align-self: center;
}

.home .edit-row {
  margin-left: auto;
}

.login-form form {
  text-align: center;
}

.login-form input,
.login-form select,
.contact-wrapper .login-form textarea {
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 16px 20px;
  width: 100%;
  margin-bottom: 25px;
  color: #809eac;
  font-size: 17px;
  line-height: 20px;
  background: var(--text-color);
}

.login-form input::placeholder,
.contact-wrapper .login-form textarea::placeholder {
  color: #809eac;
  font-size: 16px;
  line-height: 20px;
}

.login-form select {
  /* background: transparent; */
  /* background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px; */
}

.login-form p {
  color: var(--text-color);
  font-weight: 500;
  cursor: pointer;
}

.login-form button.select {
  background-color: var(--text-color);
  color: var(--primary-color);
  padding: 16px 0;
  border: none;
  outline: none;
  /* max-width: 360px; */
  width: 100%;
  margin: 40px auto 0;
}

/***** End register *****/

/***** Time *****/
.time-container {
  position: relative;
  background-image: url(../public/images/clock_bg.png);
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  overflow-x: hidden;
}

.clock-in,
.clock-out {
  position: relative;
  display: flex;
  padding: 0;
  /* margin-bottom: 60px; */
}

.time-row {
  padding: 91px 0 50px;
}

.time-row hr {
  max-width: 300px;
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background: var(--text-color);
  margin: 60px auto;
}

.clock {
  display: flex;
  justify-content: center;
}

.clock-time {
  display: flex;
  align-items: center;
}

.clock-form form {
  /* text-align: c; */
}

.clock-form button {
  max-width: 280px;
  height: 80px;
  font-family: var(--font-bold);
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--text-color);
  color: var(--primary-color);
}

.clock-out .clock-time .clock-form button,
button.select.orange-bg {
  background-color: var(--secondary-color);
}

.time-box {
  margin-bottom: 50px;
}

input.time-input:focus {
  border: 2px solid var(--text-color);
  border-color: var(--text-color) !important;
}

.time-input {
  pointer-events: none;
  position: relative;
  text-align: center;
  font-family: helvetica bold;
  font-size: 27px;
  line-height: 30px;
  color: var(--text-color);
  border: 1px solid var(--text-color);
  border-radius: 10px;
  max-width: 74px;
  width: 100%;
  padding: 21px 20px;
  outline: 0;
}

.dots {
  font-size: 3rem;
  margin: 0 7px 0 9px;
  color: var(--text-color);
}

.upload-y.upload-work,
.upload-y {
  max-width: 724px;
  width: 100%;
  border: 0;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  background-color: var(--text-color);
  padding: 10px 14px 10px 10px;
}

.upload-y {
  align-items: center;
}

.upload-y form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/***** Upload-btn *****/
.house-wrapper-y {
  position: relative;
  width: auto;
  display: inline-block;
  /* margin-top: 0.6rem; */
}

.property-from-label-y {
  font-size: 17px;
  font-weight: 600;
  position: relative;
}

.picture-preview-y {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.set-show-preview,
.show-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
}

.sell-property-image-y {
  width: 120px;
  height: 120px;
  border-radius: 5px;
}

.image-file-y {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.priview-y {
  display: flex;
  align-items: center;
}

.priview-y p {
  margin-left: 40px;
  font-size: 24px;
  color: #fff;
  margin-bottom: 0;
}

.up-btn .select {
  margin-right: 0;
  height: 50px;
  font-family: var(--font-bold);
  display: flex;
  justify-content: center;
  max-width: 165px;
  font-size: 20px;
  background: #ffba01;
  color: #fff;
  align-items: center;
  border: none;
  outline: none;
}

.house-wrapper {
  position: relative;
  width: auto;
  display: inline-block;
  /* margin-top: 0.6rem; */
}

.property-from-label {
  font-size: 17px;
  font-weight: 600;
  position: relative;
}

.sell-property-image {
  width: 100%;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.picture-preview {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 14px;
}

.show-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

.set-show-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.image-file {
  z-index: 999;
  width: 100%;
  height: 80px;
  position: relative;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

/***** End Upload-btn *****/
.work-detail {
  display: flex;
}

.work-detail .work-Img {
  position: relative;
  max-width: 140px;
  width: 100%;
  height: 111px;
  /* padding: 5px; */
  margin-left: -20px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%); */
  border-radius: 20px;
  margin-right: 40px;
}

/* .work-detail .work-Img::before{
  content: "";
    position: absolute;
    height: 198px;
    width: 200px;
    top: -55px;
    left: -59px;
  background-image:url(../public/images/preview_img.png) ;
  
} */
.work-detail .work-Img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.work-detail .work-Img .picture-preview {
  z-index: 999;
  margin-top: -3px;
}

.work-detail .work-Img img.plus-icon {
  position: absolute;
  width: 218px;
  height: 203px;
  top: 50%;
  border-radius: 20px;
  /* border: 1px dashed var(--primary-color); */
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  margin-left: 5px;
  /* width: 100%;
  height: 100%; 
  border-radius: 20px; */
  /* border: 1px dashed var(--primary-color); */
}

.upload-btn {
  max-width: 200px;
  width: 100%;
  cursor: pointer;
  position: relative;
  align-self: center;
}

.upload-btn .select {
  margin-right: 0;
}

.upload-work h3 {
  color: var(--primary-color);
  align-self: center;
  font-family: var(--font-bold);
  font-size: 29px;
  line-height: 50px;
  margin-right: 26px;
}

.react-calendar__navigation__arrow {
  display: none;
}

.react-calendar {
  width: 899px !important;
  border: none !important;
}

.react-calendar__navigation {
  margin: 0 !important;
}

abbr[title] {
  font-size: 20px;
  font-family: var(--font-bold);
  line-height: 33px;
  color: var(--text-color);
  text-decoration: none;
}

.react-calendar__month-view__days__day--weekend,
.react-calendar__tile {
  color: rgba(0, 61, 89, 30%) !important;
}

.react-calendar__tile {
  height: 70px;
}

.react-calendar__tile abbr {
  font-family: var(--font-bold);

  /* border-radius: 50px !important; */
}

.react-calendar__tile abbr.pending {
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
  background: #d64841;
  color: #fff;
  height: 40px;
  display: inline-block;
}

.react-calendar__tile.react-calendar__tile--now abbr.normal {
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
  background: transparent;
  color: unset;
  height: 40px;
  display: inline-block;
}

.react-calendar__tile abbr.success {
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
  background: #2ecf1f !important;
  color: #fff;
  height: 40px;
  display: inline-block;
}

.react-calendar__tile.react-calendar__tile--now abbr:hover,
.react-calendar__tile.react-calendar__tile--now:hover {
  color: unset !important;
}

.react-calendar__tile:enabled:hover.react-calendar__month-view__days__day abbr {
  width: 50px;
  border-radius: 50%;
  line-height: 50px;
  background: #e6e6e6;
  color: #fff;
  height: 50px;
  display: inline-block;
}

.react-calendar__tile--active,
.react-calendar__tile--now,
.react-calendar__navigation button:disabled,
.react-calendar__tile:enabled:hover {
  background: transparent !important;
}

.react-calendar__navigation__label:focus {
  background-color: transparent !important
}

.react-calendar__navigation__label {
  cursor: pointer;
  position: absolute;
  max-width: 269px;
  width: 100%;
  background-color: var(--text-color);
  color: var(--primary-color);
  font-size: 22px;
  border-radius: 5px;
  padding: 19px 0;
  text-align: center;
  left: auto;
  right: 30px;
}

.react-calendar__navigation__label__labelText {
  cursor: pointer;
  position: absolute;
  max-width: 269px;
  height: 80px;
  line-height: 40px;
  font-family: var(--font-bold);
  width: 100%;
  background-color: var(--text-color);
  color: var(--primary-color);
  font-size: 25px;
  letter-spacing: 0.5px;
  border-radius: 5px;
  padding: 19px 0;
  text-align: start;
  left: auto;
  right: 30px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-left: 40px; */
}

.calender {
  /* max-width: 899px; */
  max-width: 93.3%;
  width: 100%;
  background: var(--primary-color);
  border-radius: 30px;
  padding: 20px 30px;
  position: relative;
  /* margin: 0 auto; */
}

.react-calendar .react-calendar__tile--hasActive {
  background: transparent;
}

.calender .attend-calender {
  position: relative;
}

.attend-calender::before {
  content: "";
  height: 52px;
  border-radius: 6px;
  width: 12px;
  background-image: url(../public/images/line.png);
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  left: 20px;
  top: 10.3px;
}

.react-calendar__navigation button:enabled:hover {
  background-color: transparent !important;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 20px !important;
}

.calender .plus,
.calender .down-arrow {
  position: absolute;
  left: auto;
  right: 309px;
  top: 26px;
  z-index: 999;
}

.calender .down-arrow {
  cursor: pointer;
  right: 86px;
  top: 55px;
}

.calender h3 {
  position: relative;
  color: var(--text-color);
  font-size: 30px;
  font-family: var(--font-bold);
  line-height: 50px;
  padding-left: 27px;
  padding-left: 50px;
  padding-top: 10px;
  margin-bottom: 40px;
}

.calender .pre_abs {
  display: flex;
  margin: 30px 0 0;
}

.calender .pre_abs .absent {
  margin-left: 33px;
}

.calender .pre_abs .present h3,
.calender .pre_abs .absent h3 {
  position: relative;
  color: var(--text-color);
  font-size: 30px;
  font-family: var(--font-bold);
  line-height: 30px;
  padding-left: 30px;
  margin: 0;
}

.calender .pre_abs .present h3::before,
.calender .pre_abs .absent h3::before {
  content: "";
  height: 20px;
  width: 20px;
  background-color: #2ecf1f;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 16.3px;
}

.calender .pre_abs .absent h3::before {
  background-color: #d64841;
}

.calender hr {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  color: var(--text-color);
}

.calender-row {
  position: relative;
  /* padding-right: 25px !important; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calender-row .upload-work {
  max-width: 899px;
}

/* .calender-row .upload-work{
  position: absolute;
  bottom: 20%;
} */
/***** End Time *****/
/***** Map-Modal *****/
.map-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 12px;
  position: relative;
}

.g-map {
  max-width: 900px;
  width: 100%;
  height: 500px;
  margin: 5rem auto 0;
  border: 2px solid var(--text-color);
  border-radius: 12px;
}

.g-map div {
  border-radius: 12px;
  top: auto;
  left: auto;
}

.g-map>div {
  height: 495.5px !important;
}

.map-modal .select {
  max-width: 600px;
  margin: 70px auto 9px;
  width: 100%;
  padding: 16.5px 0;
  font-size: 18px;
  font-family: Helvetica bold, sans-serif;
  background: var(--text-color);
  color: var(--primary-color);
}

.ReactModal__Overlay {
  z-index: 999;
}

.ReactModal__Content {
  max-width: 1423.14px;
  max-height: 800px !important;
  height: auto;
  border-radius: 40px !important;
  margin: 0 auto;
  margin-top: 6rem !important;
  padding: 0px 0 !important;
}

.modal-close {
  position: absolute;
  left: auto;
  right: 15px;
  top: 14px;
  z-index: 999;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  height: 30px;
  width: 30px;
}

/***** End Map-Modal *****/
/* VKC CSS*/

/***** Start Employee Profile *****/
.my-container {
  max-width: 98%;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  text-align: end;
}

.my-container .search-input {
  padding: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 2px solid #003d59;
}

.my-container .search-input:focus-visible {
  outline: auto;
  padding: 5px;
  padding-left: 10px;
}

.profile-img {
  width: 185px;
  height: 185px;
  border-radius: 50%;
  margin: auto;
  overflow: hidden;
  border: 4px solid var(--text-color);
}

.profile-img.img-upload {
  position: relative;
  cursor: pointer;
}

.profile-img.img-upload img.img-fluid,
.profile-img img.img-fluid {
  height: 100%;
  width: 100%;
}

.profile-img.img-upload input.upload-file {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
}

.employee-name h3,
.employee-id h3 {
  font-family: Helvetica bold, sans-serif;
  font-size: 34px;
  font-weight: bold;
  color: var(--text-color);
}

.employee-profile .form-control,
.employee-profile .form-select {
  height: 55px;
}

.employee-name h3.empy_id {
  display: none;
}

.btn-property {
  background: var(--secondary-color);
  color: var(--primary-color);
  padding: 16px 0;
  border: none;
  outline: none;
  margin-top: 40px;
  max-width: 269px;
  width: 100%;
  font-size: 25px;
  font-family: Helvetica bold, sans-serif;
  position: relative;
  border-radius: 5px;
  text-align: center;
  margin-right: 30px;
  height: 80px;
}

.btn-blue {
  background-color: var(--text-color);
}

.btn-property:hover {
  background-color: var(--secondary-color);
}

.btn-property.btn-blue:hover {
  background-color: var(--text-color);
}

span.corner .plus {
  width: 10px;
  height: 10px;
  margin-left: 8px;
  position: absolute;
  top: 5px;
}

span.corner .plus-drop {
  width: 11px;
  height: 8px;
  position: absolute;
  top: 18px;
  left: auto;
  right: 11px;
}

.employee-profile {
  /* padding: 80px 0 80px; */
  /* background-color: #E2EAED; */
  background: url("../public/images/employee-profile-bg.png");
  width: 100%;
  /* height: auto; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-wrapper,
.testimonial {
  background: url("../public/images/calender_bg.png");
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  /* overflow-x: hidden; */
}

.service-wrapper .pagination.number-btn {
  display: none;
}

.employee-profile .profile-section {
  margin-bottom: 40px;
}

.employee-profile .form-control,
.employee-profile .form-select {
  height: 75px;
  border: 0;
  font-size: 16px;
  color: var(--text-color);
  padding: 0 30px;
}

.employee-profile .form-control::placeholder,
.employee-profile .form-select::placeholder {
  color: rgba(0, 61, 89, 50%) !important;
}

.employee-profile .form-control::-webkit-outer-spin-button,
.employee-profile .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.employee-profile .my-btn {
  margin-top: 50px;
  padding-bottom: 0;
}

.employee-profile .my-btn .btn-property {
  margin: 0 30px;
}

.employee-profile .myInputRow span.corner img {
  margin-left: 20px;
}

.employee-profile .myInputRow {
  position: relative;
  padding: 0 15px;
  margin-bottom: 20px;
}

/***** End Employee Profile *****/

/***** Start Manager Profile *****/

.manager-profile {
  padding: 50px 0;
}

.manager-profile .profile-img {
  margin: 0;
}

.manager-profile .employee-id h3 {
  text-align: end;
  margin: 0;
}

.manager-profile .employee-name h3 {
  padding-left: 60px;
  margin: 0;
}

.manager-profile .employee-name h3.manager-id {
  opacity: 0.5;
  text-align: start;
  padding-top: 12px;
}

.manager-profile .container {
  max-width: 75%;
}

.manager-profile .my-container {
  max-width: 1870px;
}

/***** table *****/
.detail-table {
  /* box-shadow: 0px 0px 10px 0px rgb(0 3 40);  */
  box-shadow: 0px 3px 40px 0px rgb(221 220 220);
  border-radius: 10px 10px 0 0;
  /* height: 600px;
      overflow-x: scroll; */
  /* display: block; */
}

.emp_location {
  white-space: nowrap;
  max-width: 138px !important;
  z-index: 99;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto !important;
}

.detail-table td p#date {
  padding-left: 20px;
}

.detail-table .table {
  margin-bottom: 0;
}

.empName {
  cursor: pointer;
}

.detail-table thead {
  padding: 0 15px;
  /* background: #F8FDFF; */
  /* box-shadow: 2px 10px 3px rgb(117 117 117); */
  box-shadow: 0px 3px 40px rgb(221 220 220);
  /* display: block; */
}

/* .table-striped>tbody>tr:hover {
  background: var(--text-color);
  --bs-table-accent-bg: var(--text-color);
  color: var(--primary-color);
} */
.table-striped>tbody>tr:nth-of-type(odd),
.table-striped>tbody>tr:nth-of-type(even) {
  /* background: #F8FDFF; */
  background: rgba(248, 253, 255, 70%);
  --bs-table-accent-bg: rgba(248, 253, 255, 70%);
}

.detail-table thead tr {
  height: 130px;
  border-bottom: 2px solid;
  border-color: rgb(0 61 89 / 20%);
}

.detail-table th,
.detail-table td {
  /* width: 233px; */
  vertical-align: middle;
  border: none;
}

.detail-table th span,
.detail-table td p {
  display: block;
  font-size: 23px;
  font-family: Helvetica bold, sans-serif;
  font-weight: bold;
  line-height: 35px;
  letter-spacing: 0.5px;
  color: rgb(0 61 89 / 50%);
}

.detail-table td p {
  color: var(--text-color);
  font-family: var(--font-bold);
  font-weight: bold;
  height: 60px;
  font-size: 22px;
  line-height: 60px;
  margin: 0;
}

.detail-table td button {
  color: var(--text-color);
  font-family: var(--font-bold);
  font-weight: bold;
  height: 60px;
  font-size: 17px;
  line-height: 60px;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
}

.modal-dialog {
  max-width: 100% !important;
}

.modal-dialog-scrollable .modal-content {
  width: 70%;
  margin: 0 auto;
}

.detail-table td p.Work-img {
  width: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content.employee_dtl-modal {
  max-width: 60%
}

.detail-table td p.Work-img img {
  display: none !important;
}

.detail-table td p.Work-img img:first-child {
  display: block !important;
  height: 100%;
}

.detail-table td p.status {
  width: 136px;
  border-radius: 5px;
  background-color: #6cd14e;
  color: white;
  margin: auto;
}

.detail-table td p.status.success {
  background-color: #6cd14e;
}

.detail-table td p.status.pending {
  background-color: #fc3232;
}

.detail-table tbody tr {
  height: 108px;
  padding: 0 15px;

  border-bottom: 2px solid;
  border-color: rgb(0 61 89 / 10%);
}

.detail-table tbody tr.active {
  background-color: var(--text-color);
}

.detail-table tbody tr.active td p {
  color: white;
}

.detail-table div.my-pagination {
  padding: 0 25px;
  text-align: center;
}

.pagination li:first-child {
  margin-right: 15px;
}

.detail-table div.my-pagination a.page-link {
  padding: 0;
  line-height: 80px;
  width: 80px;
  height: 80px;
  background-color: white;
  font-size: 30px;
  border-radius: 10px;
}

.detail-table div.my-pagination li.page-item.disabled a.page-link {
  background-color: var(--text-color);
}

.detail-table div.my-pagination a.page-link:before {
  font: var(--fa-font-solid);
  content: "\f0da";
  color: var(--text-color);
  font-size: 45px;
  line-height: 80px;
}

.detail-table div.my-pagination li.page-item.disabled a.page-link:before {
  font: var(--fa-font-solid);
  content: "\f0d9";
  color: white;
  font-size: 45px;
  line-height: 80px;
}

.detail-table div.my-pagination div.pagination-btn {
  display: flex;
}

.detail-table div.my-pagination div.pagination-btn p.page-no {
  padding: 0;
  line-height: 80px;
  width: 80px;
  height: 80px;
  font-family: Helvetica bold, sans-serif;
  font-weight: bold;
  color: var(--text-color);
  font-size: 30px;
}

.detail-table div.my-pagination div.pagination-btn p.page-no.active-btn {
  background-color: var(--text-color);
  color: white;
  border-radius: 10px;
}

.my-btn {
  padding-bottom: 50px;
}

.slider-btn.my-btn {
  padding-bottom: 0px;
}

.slider-btn.my-btn .btn-property {
  margin-top: 30px;
}

.page-item.dot-item {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.page-item .page-dots {
  text-decoration: none;
}

/***** End table *****/

.ReactModal__Overlay {
  z-index: 999;
  background-color: #003d59a6 !important;
}

.ReactModal__Content {
  max-width: 75%;
  border-radius: 40px !important;
  width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
}

.ReactModal__Content::-webkit-scrollbar {
  width: 1px;
  /* display: none; */
}

.ReactModal__Content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.ReactModal__Content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.modal-close {
  left: auto;
  right: 15px;
  top: 14px;
  cursor: pointer;
}

/* ===MODEL CSS==== */
.exported-detail {
  padding-top: 29px;
}

.exported-detail table {
  margin: auto;
  font-family: Helvetica bold, sans-serif;
  font-weight: bold;
  color: var(--text-color);
}

.exported-detail table tr td {
  font-family: Helvetica bold, sans-serif;
  font-weight: bold;
  font-size: 21px;
  line-height: 36px;
  /* padding-bottom: 10px; */
}

.exported-detail table tr td p {
  margin-bottom: 0;
}

.exported-detail table tr td.colon {
  padding: 0 80px 0px;
}

.slider {
  position: relative;
  overflow: hidden;
  background: url(../public/images/slider_bg.png) no-repeat;
  background-position: bottom center;
  margin-top: -50px;
  padding: 50px 0 25px 0;
}

.slider .App {
  width: 100%;
  margin: 2rem auto;
}

.slider .slide img {
  display: block;
  width: 267px;
  height: 225px;
  margin: 0 auto;
  border-radius: 42px;
}

/* .slide {
  transform: scale(0.6);
  transition: transform 300ms;
} */

.slider .slick-dots {
  position: relative;
  top: 10px;
}

.slider .mybox {
  display: none;
  border: 2px solid;
  width: 258px;
  margin: auto;
  /* left: 10px; */

  right: 0;
  top: -25px;
  bottom: 0;
  border-color: yellow;
  height: 216px;
  position: absolute;
  border-radius: 42px;
}

.slider .activeSlide .mybox ,.slider .activeSlide .img-desc{
  display: block;
}


.slider .slick-dots li button {
  border-radius: 100%;
  border: 1px solid var(--text-color);
}

.slider .slick-dots li.slick-active button {
  background: #003d59;
}

.slider .slick-dots li button:before {
  font-size: 9px;
  line-height: 23px;
  opacity: 0;
  /* height: 10px;
    width: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); */
  align-self: center;
}

.slider .slick-dots li button:hover:before,
.slider .slick-dots li button:focus:before {
  opacity: 0;
}

.slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #ffbc00;
}

.slider .slick-slide:nth-child(odd) .slide {
  transform: scale(0.55);
}

.slider .slick-slide:nth-child(even) .slide {
  transform: scale(0.7);
}

.slider .activeSlide {
  transform: scale(1) !important;
  opacity: 1;
  transition: all 300ms;
}

/* ===MODEL CSS End==== */
/***** End Manager Profile *****/
/* END VKC CSS */

/***** DatePicker *****/
.date-picker .react-datepicker {
  border: 1px solid var(--text-color);
  border-radius: 20px;
  padding: 87px 30px 20px 40px;
  position: relative;
}

.date-picker .react-datepicker__input-container {
  text-align: end;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border: 8px solid var(--text-color);
  left: auto !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: var(--text-color) !important;
}

.date-picker .react-datepicker::after {
  content: "";
  height: 52px;
  border-radius: 6px;
  width: 12px;
  background-image: url(../public/images/line.png);
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  left: 20px;
  top: 10.3px;
}

.date-picker .react-datepicker::before {
  content: "Select Date";
  position: absolute;
  font-size: 27px;
  font-family: var(--font-bold);
  line-height: 33px;
  color: var(--text-color);
  text-decoration: none;
  top: 19px;
  left: 50px;
}

.date-picker .date-picker-button {
  position: relative;
  max-width: 270px;
  height: 80px;
  width: 100%;
  background-color: var(--text-color);
  color: var(--primary-color);
  font-family: var(--font-bold);
  font-size: 25px;
  letter-spacing: 0.5px;
  border-radius: 5px;
  padding: 19px 0;
  text-align: start;
  border: none;
  outline: none;
  /* display: flex; */
  text-align: center;
  justify-content: center;
  align-items: center;
  /* padding-left: 40px; */
}

.date-picker .date-picker-button::before {
  content: "";
  height: 13px;
  width: 13px;
  background-image: url(../public/images/white-plus.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  position: absolute;
  left: 8px;
  top: 6.3px;
}

.date-picker .date-picker-button::after {
  content: "";
  height: 14px;
  width: 14px;
  background-image: url(../public/images/down-arrow.png);
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  position: absolute;
  left: auto;
  top: 32px;
  right: 26px;
}

.date-picker .react-datepicker__current-month {
  display: block;
  color: var(--text-color);
}

.date-picker .react-datepicker__navigation {
  position: absolute;
  left: auto;
  top: 50%;
}

.react-datepicker .react-datepicker__navigation-icon::before {
  border-color: var(--text-color);
  height: 14px;
  width: 14px;
}

.react-datepicker__navigation.react-datepicker__navigation--previous {
  left: 3px;
}

.date-picker .react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

.date-picker .react-datepicker__header {
  position: relative;
  border-top: 1px solid var(--text-color);
  border-radius: 0 !important;
  background-color: transparent;
  border-bottom: none;
}

.date-picker .react-datepicker__day-name,
.react-datepicker__day,
.date-picker .react-datepicker__time-name {
  margin: 1rem !important;
}

.date-picker .react-datepicker__day-name {
  font-size: 20px;
  font-family: var(--font-bold);
  line-height: 33px;
  color: var(--text-color);
  text-decoration: none;
  width: 40px;
  height: 40px;
}

.date-picker .react-datepicker__day--selected {
  border-radius: 50%;
  background-color: #2ecf1f;
  color: var(--primary-color) !important;
  vertical-align: middle;
}

.date-picker .react-datepicker__day:hover {
  border-radius: 50%;
}

.date-picker .react-datepicker__day {
  color: var(--text-color);
  font-family: var(--font-bold);
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

/***** End DatePicker *****/
/***** clock-design *****/
.Page {
  text-align: center;
  width: 100%;
  height: 100%;
}

.Watch {
  display: block;
  width: 80%;
  margin: auto;
  text-align: center;
  /* margin-bottom: 25px; */
}

.react-clock.Watch {
  position: relative;
  max-width: 288px;
  width: 100%;
  max-height: 288px;
  height: 100%;
}

.react-clock__mark__body.react-clock__minute-mark__body {
  width: 1px !important;
  top: 0px !important;
  background: #003d59 !important;
  opacity: 0.5;
}

.react-clock__mark__body {
  width: 2px !important;
  top: -6px !important;
  background-color: #ffbc00 !important;
}

.react-clock__face:nth-child(1):after {
  content: "12";
  font-size: 18px;
  /* font-family: var(--font-bold); */
  color: #ffbc00;
  position: relative;
  top: 20px;
  left: -2px;
}

.react-clock__face .react-clock__mark.react-clock__minute-mark::after {
  content: "";
  font-size: 18px;
  /* font-family: var(--font-bold); */
  color: #003d59;
  position: relative;
  top: 20px;
  left: 6px;
}

.react-clock__face .react-clock__mark.react-clock__minute-mark:nth-child(4):after {
  content: "1";
}

.react-clock__face .react-clock__mark.react-clock__minute-mark:nth-child(8):after {
  content: "2";
}

.react-clock__face .react-clock__mark.react-clock__minute-mark:nth-child(12):after {
  content: "3";
  color: #ffbc00;
  display: inline-block;
  transform: rotate(280deg);
}

.react-clock__face .react-clock__mark.react-clock__minute-mark:nth-child(16):after {
  content: "4";
  display: inline-block;
  transform: rotate(180deg);
}

.react-clock__face .react-clock__mark.react-clock__minute-mark:nth-child(20):after {
  content: "5";
  display: inline-block;
  transform: rotate(180deg);
  /* transform: rotate(45deg); */
}

.react-clock__face .react-clock__mark.react-clock__minute-mark:nth-child(24):after {
  content: "6";
  display: inline-block;
  transform: rotate(186deg);
  color: #ffbc00;
}

.react-clock__face .react-clock__mark.react-clock__minute-mark:nth-child(28):after {
  content: "7";
  display: inline-block;
  transform: rotate(183deg);
}

.react-clock__face .react-clock__mark.react-clock__minute-mark:nth-child(32):after {
  content: "8";
  display: inline-block;
  transform: rotate(187deg);
}

.react-clock__face .react-clock__mark.react-clock__minute-mark:nth-child(36):after {
  content: "9";
  display: inline-block;
  transform: rotate(92deg);
  color: #ffbc00;
}

.react-clock__face .react-clock__mark.react-clock__minute-mark:nth-child(40):after {
  content: "10";
  display: inline-block;
  transform: rotate(63deg);
  left: 5px;
}

.react-clock__face .react-clock__mark.react-clock__minute-mark:nth-child(44):after {
  content: "11";
  display: inline-block;
  transform: rotate(36deg);
  left: 5px;
}

.react-clock__face {
  border: none !important;
}

.react-clock.Watch::after {
  font: var(--fa-font-solid);
  content: "\f111";
  color: white;
  font-size: 6px;
  line-height: 18px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  background-color: #003d59;
  display: block;
  z-index: 666;
}

.react-clock__hand__body.react-clock__hour-hand__body {
  bottom: 50% !important;
  width: 4px;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  top: 15%;
  bottom: 41%;
  border-bottom: 75px solid #003d59;
  /* box-shadow: 2px 10px 4px rgb(221 220 220); */
}

.react-clock__hand__body.react-clock__minute-hand__body {
  width: 4px;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  top: 15%;
  bottom: 41%;
  border-bottom: 100px solid #003d59;
  bottom: 50% !important;
  box-shadow: 2px 10px 4px rgb(221 220 220);
}

.react-clock__hand__body.react-clock__second-hand__body {
  bottom: 50% !important;
}

.react-clock__hand__body {
  background-color: transparent !important;
}

.react-clock__second-hand__body {
  background-color: transparent !important;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-bottom: 128px solid #ff1756;
}

/***** Section>1400 *****/
.select.select-btn {
  height: 47px;
  width: 172px;
  font-family: Helvetica bold, sans-serif;
  font-weight: bold;
  margin-right: 30px !important;
}

.select.select-btn p {
  font-size: 18.5px;
}

/***** End Section>1400 *****/

/***** End clock-design *****/
/***** Pagination *****/

.paginate-button {
  padding: 20px 42px;
  border-radius: 0 0 10px 10px;
  background: rgba(248, 253, 255, 70%);
}

.paginate-button .pagination {
  margin: 0;
}

.pagination.number-btn li {
  margin: 0 7px 0 0;
}

.pagination.number-btn li a {
  line-height: 25px;
  color: #003d59;
  font-family: var(--font-bold);
}

.pagination .page-link {
  height: 40px;
  width: 40px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
}

/* .pagination .page-link.prev-page,
.pagination.number-btn li:first-child .page-link,
.pagination.number-btn li:last-child .page-link {
  color: #fff;
  background: #003d59;
} */
.pagination .page-link.prev-page,
.pagination.number-btn li .page-link.active {
  color: #fff;
  background: #003d59;
}

.pagination .page-link.next-page {
  color: #003d59;
  background: #fff;
}

.pagination .page-item .page-link {
  border-radius: 5px;
}

.pagination .page-item .page-link .fa-solid {
  font-size: 20px;
  line-height: 25px;
}

/***** End Pagination *****/

/* Services List */
.services-check {
  text-align: start;
  padding: 0 30px;
}

.services-check h3 {
  color: #003d59;
  margin: 1rem auto 1.5rem;
  font-size: 29px;
}

.services-check .form-label {
  color: #003d59;
  font-size: 18px;
  font-weight: 600;
}

.tracker-btn:focus {
  border-color: var(--text-color) !important;

}

.tracker-btn {
  margin-left: 10px;
  background: transparent;
  border: 1.7px solid var(--text-color);
  height: 20px;
  width: 27px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.tracker-list {
  display: flex;
  justify-content: space-around;
}

.tracker-list p {
  font-size: 14px;
  word-break: break-all;
  margin-bottom: .5rem;
}

.tracker-list button.tracker-btn {
  border: none;
}

.services-check .col {}

@media screen and (max-width: 1657px) and (min-width: 1400px) {
  .upload-work h3 {
    font-size: 20px;
  }

  .upload-work {
    padding: 14px;
  }
}

@media screen and (max-width: 1800px) {
  .contact-us .edit-row {
    margin-left: unset;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender {
    max-width: 825px;
  }
}

@media screen and (max-width: 1560px) and (min-width: 1129px) {
  .profile-body {
    right: 0;
    position: absolute;
    top: calc(100% + 37px);
  }
}

@media screen and (max-width: 1700px) {
  .slider .mybox {
    left: 0exported-detail table tr td;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender {
    max-width: 783px;
  }

  .calender-row .upload-work h3 {
    font-size: 27px;
  }

  .navbar-expand-lg .navbar-nav a {
    font-size: 17px;
  }

  /* .nav-logo {
    max-width: 90px;
  } */
  .navbar .select-btn .form-select {
    max-width: 165px;
    height: 45px;
  }

  .message {
    font-size: 17px;
    padding: 17px 15px;
  }

  .large-text {
    font-size: 60px;
  }

  .medium-text {
    font-size: 33px;
    margin-bottom: 20px;
  }

  .small-text {
    font-size: 16px;
    line-height: 35px;
    max-width: 490px;
  }

  .nav-pills .nav-link {
    font-size: 24px;
    padding: 12px 0;
  }

  .login-form input,
  .login-form select,
  .contact-wrapper .login-form textarea {
    padding: 15px 20px;
    margin-bottom: 25px;
    font-size: 15px;
    line-height: 17px;
  }

  .login-form button.select {
    padding: 10px 0;
    margin: 30px auto 0;
  }

  .home-container .select {
    margin-top: 35px;
    padding: 10px 0;
    max-width: 240px;
  }

  .profile-img {
    width: 175px;
    height: 175px;
  }

  .employee-name h3,
  .employee-id h3 {
    font-size: 29px;
  }

  .employee-profile .form-control,
  .employee-profile .form-select {
    height: 55px;
    font-size: 18px;
  }

  .btn-property {
    padding: 10px 0;
    margin-top: 40px;
    max-width: 240px;
    font-size: 25px;
    height: 60px;
  }

  .employee-profile .my-btn .btn-property {
    margin: 0 20px;
  }

  .employee-profile {
    /* padding: 80px 0 40px; */
  }

  .clock-form button {
    padding: 0;
    height: 60px;
  }

  .react-calendar__navigation__label__labelText {
    max-width: 245px;
    height: 60px;
    font-size: 21px;
    padding: 10px 30px;
  }

  .calender .plus {
    right: 282px;
  }

  .calender .down-arrow {
    top: 46px;
  }

  .calender h3 {
    font-size: 24px;
  }

  .react-calendar__navigation {
    height: 20px !important;
  }

  .calender .pre_abs .present h3,
  .calender .pre_abs .absent h3 {
    font-size: 23px;
  }

  .calender .pre_abs .present h3::before,
  .calender .pre_abs .absent h3::before {
    height: 15px;
    width: 15px;
    top: 18.3px;
  }

  .manager-profile .employee-name h3 {
    padding-left: 30px;
  }

  .date-picker .date-picker-button {
    max-width: 245px;
    height: 60px;
    font-size: 22px;
    padding: 10px 0px;
    padding-left: 40px;
  }

  .date-picker .date-picker-button::after {
    top: 25px;
  }

  .detail-table th span {
    font-size: 21px;
  }

  .detail-table td p {
    font-size: 18px;
  }

  .detail-table div.my-pagination li.page-item.disabled a.page-link:before,
  .detail-table div.my-pagination a.page-link:before {
    font-size: 30px;
    line-height: 65px;
  }

  .detail-table div.my-pagination a.page-link {
    line-height: 65px;
    width: 65px;
    height: 65px;
  }

  .detail-table div.my-pagination div.pagination-btn p.page-no {
    line-height: 65px;
    width: 65px;
    height: 65px;
  }
}

@media screen and (max-width: 1634px) {

  .calender-row .upload-work,
  .upload-work,
  .calender {
    max-width: 740px;
  }

  .centered-text {
    max-width: 165px;
  }

  .centered-text img {
    max-width: 182px;
  }

  .calender-row .upload-work h3,
  .upload-work h3 {
    font-size: 22px;
  }
}

@media screen and (max-width: 1500px) {

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 690px;
  }

  .priview-y p {
    font-size: 20px;
  }

  .navbar-expand-lg .navbar-nav {
    /* margin-right: 107px; */
  }

  hr {
    width: 81.4%;
  }

  .calender-row .upload-work h3,
  .upload-work h3 {
    font-size: 21px;
  }

  .react-calendar__navigation__label__labelText {
    right: 0;
    top: 26px;
  }

  .calender .down-arrow {
    right: 66px;
    top: 50px;
  }

  .calender .plus {
    top: 32px;
    right: 257px;
  }

  .mybox {
    left: 0;
  }
}

@media screen and (max-width: 1450px) {

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 600px;
  }

  .upload-work h3 {
    font-size: 18px;
    margin: 0;
    line-height: 35px;
  }

  .calender-row .upload-work h3 {
    font-size: 18px;
    margin: 0;
    line-height: 35px;
  }

  .work-detail .work-Img {
    margin-right: 0;
  }
}

@media (min-width: 1401px) {
  .react-clock__hand__body.react-clock__minute-hand__body {
    border-bottom: 80px solid #003d59;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
  }

  .react-clock__hand__body.react-clock__hour-hand__body {
    border-bottom: 60px solid #003d59;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .react-clock__second-hand__body {
    border-bottom: 110px solid #ff1756;
  }

  .react-clock.Watch {
    height: 260px !important;
    max-width: none;
  }

  h6.message::before {
    left: -81%;
  }

  .large-text {
    font-size: 58px;
  }

  .my-btn .btn-property {
    height: 50px;
    line-height: 0;
    max-width: 200px;
    font-size: 18px;
  }

  .calender .down-arrow {
    right: 48px;
    top: 42px;
  }

  .react-calendar__navigation__label__labelText {
    right: 0;
    font-size: 17.5px;
    height: 50px;
    padding: 7px 20px;
    max-width: 210px;
  }

  .dots {
    font-size: 2rem;
  }

  .upload-work h3,
  .calender .pre_abs .present h3,
  .calender .pre_abs .absent h3 {
    padding-left: 19px;
    font-size: 17px;
    line-height: 23px;
  }

  .calender {
    border-radius: 18px;
    padding: 10px 30px;
  }

  .calender .pre_abs .absent {
    margin-left: 18px;
  }

  .calender .pre_abs {
    margin: 5px 0 0;
  }

  .calender .pre_abs .present h3::before,
  .calender .pre_abs .absent h3::before {
    height: 10px;
    width: 10px;
  }

  /* .react-clock.Watch{
  max-height: 252px;
}
.react-clock__hand__body.react-clock__second-hand__body{
  top: 0;
  bottom: 47%;
} */
  .contact-wrapper .login-form.edit-row {
    padding-top: 0;
  }

  .contact-wrapper .edit-row h2 {
    font-size: 40px;
  }

  .upload-work {
    max-width: 724px;
  }

  .calender-row {
    display: flex;
    align-items: center;
  }

  .calender {
    margin-bottom: 2rem;
  }

  .clock-in,
  .clock-out {
    max-width: 727px;
    width: 100%;
  }

  .clock-time {
    justify-content: center;
  }

  h1 {
    font-size: 55px;
  }

  h2 {
    font-size: 28px;
  }

  .navbar-expand-lg .navbar-nav a {
    font-size: 17px;
  }

  .navbar .select-btn .myInputRow {
    /* height: 45px;
    width: 170px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar .select-btn .form-select {
    height: 45px;
    width: 170px;
  }

  .message {
    padding: 16px;
    font-size: 15px;
  }

  .medium-text {
    font-size: 25px;
    margin-bottom: 15px;
  }

  .small-text {
    font-size: 15px;
    line-height: 34px;
    max-width: 450px;
  }

  .home-container .select {
    max-width: 200px;
  }

  .login-form button.select,
  .home-container .select,
  .clock-out .clock-time .clock-form button,
  .clock-form button,
  .date-picker .date-picker-button,
  .detail-table td p.status {
    height: 50px;
    font-size: 17.5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-form button.select {
    margin-top: 22px;
  }

  .employee-profile .form-control,
  .employee-profile .form-select {
    height: 55px;
  }

  .date-picker .date-picker-button {
    max-width: 230px;
  }

  .time-input {
    padding: 15px 20px;
    max-width: 71px;
  }

  .detail-table td p.status {
    height: 44px;
    font-size: 17px !important;
  }

  .date-picker .date-picker-button {
    font-size: 17px;
  }

  /* .employee-name h3, .employee-id h3{
  font-size: 31px;
} */
  /* .profile-img {
    width: 185px;
    height: 185px;
} */
  .centered-text {
    max-width: 108px;
  }

  .centered-text img {
    max-width: 152px;
  }

  .detail-table .my-pagination {
    width: 100%;
    align-items: center;
  }

  .clock-form button,
  .date-picker .date-picker-button {
    margin: 0 0 0 auto;
  }

  .exported-detail table {
    font-size: 23px;
  }

  .detail-table thead tr {
    height: 90px;
  }

  .detail-table td p {
    font-size: 17px;
  }

  .detail-table tbody tr {
    padding: 0;
    height: 80px;
  }

  .detail-table th span {
    font-size: 20px;
    line-height: 30px;
  }

  .detail-table th,
  .detail-table td {
    padding: 0;
  }

  .detail-table div.my-pagination a.page-link,
  .detail-table div.my-pagination div.pagination-btn p.page-no.active-btn,
  .detail-table div.my-pagination div.pagination-btn p.page-no {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 19px;
  }

  .detail-table div.my-pagination div.pagination-btn {
    align-items: center;
  }

  .detail-table div.my-pagination li.page-item.disabled a.page-link:before,
  .detail-table div.my-pagination a.page-link:before {
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .date-picker .date-picker-button {
    max-width: 210px;
    padding: 0px 30px;
    justify-content: center;
    text-align: start;
    font-size: 19px;
  }

  .date-picker .date-picker-button::after {
    top: 18px;
  }

  .calender .plus {
    right: 220px;
  }
}

@media screen and (max-width: 1400px) {
  .upload-y {
    margin-bottom: 2rem;
  }

  .dots {
    font-size: 2rem;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender {
    max-width: 900px;
    margin-bottom: 2rem;
  }

  .upload-work h3,
  .calender .pre_abs .present h3,
  .calender .pre_abs .absent h3 {
    padding-left: 19px;
    font-size: 17px;
    line-height: 23px;
  }

  .calender {
    border-radius: 18px;
    padding: 10px 30px;
  }

  .calender .pre_abs .absent {
    margin-left: 18px;
  }

  .calender .pre_abs {
    margin: 5px 0 0;
  }

  .calender .pre_abs .present h3::before,
  .calender .pre_abs .absent h3::before {
    height: 10px;
    width: 10px;
  }

  /* .react-clock.Watch{
  max-height: 252px;
}
.react-clock__hand__body.react-clock__second-hand__body{
  top: 0;
  bottom: 47%;
} */
  .contact-wrapper .login-form.edit-row {
    padding-top: 0;
  }

  .contact-wrapper .edit-row h2 {
    font-size: 40px;
  }

  .upload-work {
    max-width: 724px;
  }

  .calender-row {
    display: flex;
    align-items: center;
  }

  .calender {
    margin-bottom: 2rem;
  }

  .clock-in,
  .clock-out {
    max-width: 727px;
    width: 100%;
  }

  .clock-time {
    justify-content: center;
  }

  h1 {
    font-size: 55px;
  }

  h2 {
    font-size: 28px;
  }

  .navbar-expand-lg .navbar-nav a {
    font-size: 17px;
  }

  .navbar .select-btn .myInputRow {
    /* height: 45px;
    width: 170px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar .select-btn .form-select {
    height: 45px;
    width: 170px;
  }

  .message {
    padding: 16px;
    font-size: 15px;
  }

  h6.message::before {
    left: -50%;
  }

  .large-text {
    font-size: 48px;
  }

  .medium-text {
    font-size: 25px;
    margin-bottom: 15px;
  }

  .small-text {
    font-size: 15px;
    line-height: 34px;
    max-width: 450px;
  }

  .home-container .select {
    max-width: 200px;
  }

  .login-form button.select,
  .home-container .select,
  .clock-out .clock-time .clock-form button,
  .clock-form button,
  .date-picker .date-picker-button,
  .detail-table td p.status {
    height: 50px;
    font-size: 17.5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-form button.select {
    margin-top: 22px;
  }

  .employee-profile .form-control,
  .employee-profile .form-select {
    height: 58px;
  }

  .my-btn .btn-property {
    height: 50px;
    line-height: 0;
    max-width: 200px;
    font-size: 19px;
  }

  .date-picker .date-picker-button {
    max-width: 230px;
  }

  .time-input {
    padding: 15px 20px;
    max-width: 71px;
  }

  .detail-table td p.status {
    height: 44px;
    font-size: 17px !important;
  }

  .date-picker .date-picker-button {
    font-size: 17px;
  }

  /* .employee-name h3, .employee-id h3{
  font-size: 31px;
} */
  /* .profile-img {
    width: 185px;
    height: 185px;
} */
  .centered-text {
    max-width: 108px;
  }

  .centered-text img {
    max-width: 152px;
  }

  .detail-table .my-pagination {
    width: 1324px;
    align-items: center;
  }

  .clock-form button,
  .date-picker .date-picker-button {
    margin: 0 0 0 auto;
  }

  .exported-detail table {
    font-size: 23px;
  }

  .detail-table thead tr {
    height: 90px;
  }

  .detail-table td p {
    font-size: 17px;
  }

  .detail-table tbody tr {
    padding: 0;
    height: 80px;
  }

  .detail-table th span {
    font-size: 20px;
    line-height: 30px;
  }

  .detail-table th,
  .detail-table td {
    padding: 0;
  }

  .detail-table div.my-pagination a.page-link,
  .detail-table div.my-pagination div.pagination-btn p.page-no.active-btn,
  .detail-table div.my-pagination div.pagination-btn p.page-no {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 19px;
  }

  .detail-table div.my-pagination div.pagination-btn {
    align-items: center;
  }

  .detail-table div.my-pagination li.page-item.disabled a.page-link:before,
  .detail-table div.my-pagination a.page-link:before {
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .react-calendar__navigation__label__labelText {
    right: 0;
    font-size: 18.5px;
    height: 50px;
    padding: 7px 20px;
    max-width: 210px;
  }

  .date-picker .date-picker-button {
    max-width: 210px;
    padding: 0px 30px;
    justify-content: center;
    text-align: start;
    font-size: 19px;
  }

  .date-picker .date-picker-button::after {
    top: 18px;
  }

  .calender .plus {
    right: 220px;
  }

  .calender .down-arrow {
    right: 56px;
    top: 49px;
  }

  .select.select-btn {
    font-size: 18px;
    height: 40px;
    width: 160px;
  }

  .select.select-btn span.corner .plus-drop {
    top: 16px;
  }

  .modal-content.employee_dtl-modal {
    max-width: 80%
  }
}

@media screen and (max-width: 1324px) {
  .tab-content {
    padding: 25px;
  }

  .login-form input::placeholder,
  .contact-wrapper .login-form textarea::placeholder {
    color: #809eac;
    font-size: 15px;
    line-height: 20px;
  }

  .nav-pills .nav-link {
    font-size: 21px;
  }

  .navbar-expand-lg .navbar-nav {
    /* margin-right: 30px; */
  }

  hr {
    width: 86.3%;
  }

  .detail-table {
    overflow-x: scroll;
  }

  .detail-table table {
    width: 1324px;
  }

  #password-modal,
  #clock-out-property,
  #clock-in-property,
  #trackerModal {
    max-width: 50%;
  }

  #upload-out-modal {
    max-width: 70%;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .react-clock__hand__body.react-clock__minute-hand__body {
    border-bottom: 70px solid #003d59;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
  }

  .react-clock__hand__body.react-clock__hour-hand__body {
    border-bottom: 54px solid #003d59;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .react-clock__second-hand__body {
    border-bottom: 100px solid #ff1756;
  }

  .react-clock.Watch {
    height: 230px !important;
    max-width: none;
  }

  .clock-time {
    justify-content: center;
  }

  .time-input {
    padding: 11px 15px;
    max-width: 60px;
    font-size: 22px;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 600px;
  }

  .sell-property-image-y {
    width: 100px;
    height: 100px;
    border-radius: 5px;
  }

  .priview-y p {
    margin: 0 30px 0 20px;
    font-size: 19px;
  }

  .up-btn .select {
    max-width: 145px;
  }

  .time-box {
    margin-bottom: 35px;
  }

  .attend-calender::before {
    left: -7px;
    height: 50px;
    width: 10px;
  }

  .calender h3 {
    padding-left: 16px;
    font-size: 22.5px;
    margin-bottom: 27px;
  }

  .react-calendar__month-view__weekdays__weekday,
  .react-calendar__tile {
    padding: 0;
  }

  .react-calendar__tile {
    height: 53px;
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 7px !important;
  }

  .react-calendar__tile--now.react-calendar__month-view__days__day abbr,
  .react-calendar__tile--active.react-calendar__month-view__days__day abbr,
  .react-calendar__tile:enabled:hover.react-calendar__month-view__days__day abbr {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 12px;
  }

  .react-calendar__tile abbr {
    font-size: 12px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  abbr[title] {
    font-size: 15.8px;
  }
}

@media screen and (max-width: 1250px) and (min-width: 992px) {
  .home-container {
    max-width: 84.91%;
  }

  .large-text {
    font-size: 43px;
  }

  .small-text {
    font-size: 14px;
  }

  .react-clock.Watch::after {
    font-size: 4px;
    line-height: 14.5px;
    width: 14px;
    height: 14px;
  }

  .react-clock__hand__body.react-clock__minute-hand__body {
    border-bottom: 67px solid #003d59;
    border-right: 2.1px solid transparent;
    border-left: 2.1px solid transparent;
  }

  .react-clock__hand__body.react-clock__hour-hand__body {
    border-bottom: 51px solid #003d59;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .react-clock__second-hand__body {
    border-bottom: 85px solid #ff1756;
  }

  .react-clock__face:nth-child(1):after {
    font-size: 14px;
    top: 14px;
    left: 0px;
  }

  .react-clock__face .react-clock__mark.react-clock__minute-mark::after {
    top: 12px;
    left: 3px;
    font-size: 14px;
  }

  .navbar-expand-lg .navbar-nav a {
    padding: 0 0.7rem;
    font-size: 16px;
  }

  .react-clock.Watch {
    height: 196px !important;
  }

  .clock-time {
    justify-content: start;
  }

  .time-input {
    padding: 7px 11px;
    max-width: 50px;
    font-size: 17px;
  }

  .clock-out .clock-time .clock-form button,
  .clock-form button,
  .react-calendar__navigation__label__labelText {
    height: 45px;
    font-size: 16.3px;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 470px;
  }

  /* .upload-y{max-width: 500px;} */
  .sell-property-image-y {
    width: 90px;
    height: 90px;
    border-radius: 5px;
  }

  .priview-y p {
    margin: 0 10px 0 14px;
    font-size: 15px;
  }

  .up-btn .select {
    max-width: 131px;
    font-size: 17px;
    height: 44px;
  }

  .react-calendar__navigation__label__labelText {
    max-width: 178px;
    height: 45px;
    font-size: 15.3px;
  }

  .time-box {
    margin-bottom: 35px;
  }

  .attend-calender::before {
    left: -11px;
    height: 46px;
    width: 10px;
  }

  .calender .down-arrow {
    right: 46px;
    top: 46px;
  }

  .work-detail .work-Img .picture-preview {
    margin-left: 11px;
  }

  .upload-work {
    border-radius: 16px;
  }

  .calender-row .upload-work h3 {
    font-size: 12.5px;
  }

  .upload-btn {
    max-width: 145px;
  }

  .centered-text {
    max-width: 100px;
  }

  .centered-text img {
    max-width: 130px;
  }

  .calender .plus {
    right: 190px;
  }

  .calender h3 {
    padding-left: 15px;
    font-size: 18.5px;
    margin-bottom: 27px;
  }

  .react-calendar__month-view__weekdays__weekday,
  .react-calendar__tile {
    padding: 0;
  }

  .react-calendar__tile {
    height: 53px;
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 7px !important;
  }

  .react-calendar__tile--now.react-calendar__month-view__days__day abbr,
  .react-calendar__tile--active.react-calendar__month-view__days__day abbr,
  .react-calendar__tile:enabled:hover.react-calendar__month-view__days__day abbr {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 12px;
  }

  .react-calendar__tile abbr {
    font-size: 12px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  abbr[title] {
    font-size: 15.8px;
  }

  /* .navbar .select-btn .myInputRow {
  max-width: 175px;
} */
}

@media screen and (max-width: 1134px) {
  .employee-profile {
    height: auto;
    padding: 40px 0;
  }

  .profile-body {
    right: 0;
  }

  /* .navbar-expand-lg .navbar-toggler {
    display: block;
}
.navbar-expand-lg .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
} */
  hr {
    width: 96.3%;
  }

  .profile-img {
    width: 170px;
    height: 170px;
  }

  .employee-name h3,
  .employee-id h3 {
    font-size: 30px;
  }

  .manager-profile .employee-name h3 {
    padding-left: 25px;
  }

  .detail-table div.my-pagination a.page-link {
    line-height: 55px;
    width: 55px;
    height: 55px;
  }

  .detail-table div.my-pagination div.pagination-btn p.page-no {
    line-height: 55px;
    width: 55px;
    height: 55px;
  }

  .main-header li.nav-item {
    padding: 0;
  }
}

@media screen and (max-width: 991px) {

  .home-container {
    padding: 120px 31px 80px;
    margin: 67px auto 0;
    height: auto;
  }

  #home .row>.col-lg-6 {
    height: auto;
  }

  .edit-row {
    width: 75%;
  }

  .edit-row.logout-row {
    display: none;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .home {
    height: auto;
  }

  .home .edit-row {
    margin: auto;
  }

  .show ul.navbar-nav {
    margin-right: 0;
  }

  .show ul.navbar-nav li {
    margin-bottom: 2rem;
  }

  .react-clock__hand__body.react-clock__minute-hand__body {
    border-bottom: 77px solid #003d59;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
  }

  .react-clock__hand__body.react-clock__hour-hand__body {
    border-bottom: 61px solid #003d59;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .react-clock__second-hand__body {
    border-bottom: 110px solid #ff1756;
  }

  .react-clock.Watch {
    height: 250px !important;
  }

  .time-input {
    padding: 11px 15px;
    max-width: 60px;
    font-size: 22px;
  }

  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 30px;
  }

  .message {
    font-size: 20px;
  }

  .medium-text {
    margin-bottom: 22px;
  }

  .small-text {
    font-size: 20px;
    line-height: 43px;
    max-width: 85%;
  }

  .navbar .profile {
    margin-right: 10px;
  }

  .navbar .select-btn {
    margin-right: 10px;
  }

  .navbar .select-btn .myInputRow {
    width: 100%;
    /* padding:  5px 10px; */
    max-width: 130px;
    margin-left: 10px;
  }

  .navbar .select-btn .form-select {
    font-size: 17px;
    max-width: 130px;
    width: 100%;
  }

  /* .nav-logo {
    max-width: 90px;
  } */

  /* .navbar .profile-pic{
    width: 70px;
    border-radius: 100%;
    height: unset;
  } */

  .navbar .navbar-collapse {
    padding-top: 21px;
    text-align: center;
  }

  .navbar .nav-item {
    padding: 22px 0;
  }

  .manager-profile .container {
    max-width: 90%;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 724px;
    width: 100%;
  }

  .upload-work h3 {
    font-size: 21px;
    line-height: 36px;
    margin-left: -16px;
  }

  .contact-form {
    margin-top: 5rem;
  }

  .exported-detail table {
    font-size: 18px;
  }

  .ReactModalPortal .btn-property {
    width: 200px;
    padding: 10px 0;
  }

  .centered-text {
    max-width: 120px;
  }

  .centered-text img {
    max-width: 165px;
  }

  .contact-wrapper .home-container {
    padding: 0px 31px 30px;
    justify-content: center;
    margin-top: 0 !important;
  }

  .contact-wrapper .login-form.edit-row {
    margin: auto;
  }

  .date-picker .date-picker-button {
    max-width: 200px;
    font-size: 15px;
    justify-content: center;
  }

  .date-picker .date-picker-button::after {
    top: 19px;
    right: 30px;
  }

  .my-btn .btn-property {
    font-size: 17px;
  }

  .employee-name h3,
  .employee-id h3 {
    font-size: 26px;
  }

  .profile-img {
    width: 160px;
    height: 160px;
  }

  .detail-table th span {
    font-size: 18px;
    line-height: 28px;
  }

  .detail-table td p {
    font-size: 15px;
    height: 45px;
    line-height: 45px;
  }

  .detail-table td p.status {
    height: 44px;
    font-size: 14px !important;
  }

  .detail-table td p.Work-img {
    width: 45px;
  }

  .profile-pic .profile-body {
    width: 150px;
    left: auto;
    padding: 11px 7px 7px;
    top: calc(100% + 32px);
  }

  .profile-pic .profile-body::before {
    top: -7px;
    width: 15px;
    height: 15px;
  }

  .profile-pic .profile-item a {
    font-size: 15px;
    line-height: 15px;
  }

  .select.select-btn {
    margin-right: 5px !important;
    margin-left: 15px;
    font-size: 17px;
    max-width: 130px;
    /* width: 100%; */
  }

  .profile-pic {
    width: 50px;
    height: 50px;
  }

  .op-select {
    width: 148px;
  }

  #password-modal,
  #clock-out-property,
  #clock-in-property,
  #trackerModal {
    max-width: 60%;
  }
}

@media screen and (max-width:991px) and (min-width:900px) {
  .large-text {
    font-size: 55px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 30px;
  }

  .small-text {
    font-size: 18px;
    line-height: 40px;
  }

  .edit-row {
    width: 80%;
  }

  .clock-form button,
  .date-picker .date-picker-button {
    margin: 0 auto;
  }

  .message {
    font-size: 16px;
  }

  .employee-profile .form-control,
  .employee-profile .form-select {
    height: 53px;
    font-size: 16px;
  }

  .tab-content {
    padding: 30px 15px 25px;
  }

  .login-form button.select,
  .home-container .select,
  .clock-out .clock-time .clock-form button,
  .clock-form button,
  .date-picker .date-picker-button,
  .detail-table td p.status {
    font-size: 16px;
  }

  .detail-table div.my-pagination a.page-link,
  .detail-table div.my-pagination div.pagination-btn p.page-no.active-btn,
  .detail-table div.my-pagination div.pagination-btn p.page-no {
    height: 35px;
    width: 35px;
    line-height: 34px;
    font-size: 14px;
  }

  .nav-pills .nav-link {
    font-size: 22px;
  }

  .login-form button.select {
    padding: 12px 0;
    font-size: 17px;
  }

  .home-container .select {
    margin-top: 24px;
    font-size: 18px;
  }

  .select {
    font-size: 22px;
    padding: 14px 0;
  }

  .employee-profile .my-btn .btn-property {
    margin: 10px 20px;
  }

  .employee-profile .my-btn {
    margin-top: 20px;
  }

  .my-btn .btn-primary:nth-child(2) {
    margin-right: 0;
  }

  .employee-profile {
    /* padding:70px 0 0 ; */
  }

  .exported-detail table tr td.colon {
    padding: 0px 20px;
  }

  .manager-profile .employee-id h3 {
    text-align: center;
    margin-top: 15px;
    display: none;
  }

  .employee-name h3.empy_id {
    display: block;
  }

  .manager-profile .profile-section div.d-flex {
    justify-content: center;
  }

  .calender h3 {
    font-size: 24px;
  }

  .react-calendar__navigation__label__labelText {
    right: 0;
    font-size: 15.5px;
    /* max-width: 233px; */
  }

  .calender .plus {
    right: 221px;
  }

  .calender .down-arrow {
    right: 59px;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 647px;
  }

  .priview-y p {
    font-size: 20px;
    margin-left: 20px;
  }

  .up-btn .select {
    max-width: 135px;
    font-size: 16px;
  }

  .sell-property-image-y {
    height: 100px;
    width: 100px;
  }

  .upload-work h3 {
    font-size: 21px;
    line-height: 36px;
    margin-left: -16px;
  }

  .date-picker .react-datepicker__input-container {
    text-align: center;
    margin-top: 2rem;
  }

  .clock-time {
    justify-content: center;
  }

  .login-form input::placeholder,
  .contact-wrapper .login-form textarea::placeholder {
    color: #809eac;
    font-size: 14px;
    line-height: 20px;
  }

  .modal-close {
    width: 40px;
  }

  #password-modal,
  #clock-out-property,
  #clock-in-property,
  #trackerModal {
    max-width: 70%;
  }
}

@media screen and (max-width: 700px) {

  .clock-in,
  .clock-out {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .clock-time {
    margin-top: 2rem;
  }
}

@media screen and (max-height:700px) {
  .map-modal.upload-modal {
    /* top: 0%;
    transform: translateY(0); */
  }
}

@media screen and (max-width: 667px) {
  .priview-y p {
    font-size: 16px;
    margin: 0 20px;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 575px;
  }

  .upload-work h3 {
    font-size: 20px;
    line-height: 36px;
    margin: 0;
    margin-left: -16px;
  }

  .centered-text {
    max-width: 123px;
  }

  .centered-text img {
    max-width: 170px;
  }

  .calender-row .upload-work .work-detail h3 {
    font-size: 18px;
  }

  h6.message::before {
    left: -53%;
    /* transform: translateY(10px); */
  }

  .edit-row {
    max-width: 100%;
    width: 100%;
  }

}

@media screen and (max-width: 575px) {
  .navbar-brand img {
    max-width: 70px;
  }

  .navbar {
    max-width: calc(100% - 32px);
  }

  .home-container {
    padding: 120px 0px 30px;
    margin-top: 0;
  }

  .btn-primary {
    height: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto !important;
  }

  .my-btn {
    display: flex;
  }

  .select {
    margin-right: 0;
  }

  .medium-text {
    font-size: 17px;
  }

  .large-text {
    font-size: 34px;
  }

  .profile-pic .profile-body {
    top: calc(100% + 23px);
  }

  /* .navbar .profile-pic {
    width: 50px;
} */
  /* .time-row hr{
  max-width: 400px;
} */
  .calender {
    max-width: 505px;
    padding: 20px 5px;
  }

  .calender h3 {
    font-size: 20px;
    padding-left: 41px;
  }

  .react-calendar__navigation__label__labelText {
    right: -23px;
    max-width: 183px;
  }

  .home-container .select {
    font-size: 15px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 170px;
    margin: 24px auto 0;
  }

  .login-form button.select {
    max-width: 170px;
    height: 44px;
  }

  .profile-img {
    height: 150px;
    width: 150px;
  }

  .employee-name h3,
  .employee-id h3 {
    font-size: 24px;
  }

  .employee-profile .form-control,
  .employee-profile .form-select {
    height: 47px;
    font-size: 14px;
  }

  .login-form button.select,
  .home-container .select,
  .clock-out .clock-time .clock-form button,
  .clock-form button,
  .date-picker .date-picker-button,
  .detail-table td p.status {
    font-size: 16px;
  }

  .small-text {
    max-width: 100%;
    font-size: 13px;
    line-height: 35px;
  }

  .nav-pills .nav-link {
    font-size: 18px;
  }

  .message {
    font-size: 12px;
    padding: 17px;
  }

  .calender .plus {
    right: 173px;
  }

  .calender .down-arrow {
    right: 22px;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 489px;
  }

  .upload-work {
    padding-right: 0;
  }

  .upload-work h3 {
    font-size: 16px;
    line-height: 30px;
    margin-left: -24px;
  }

  .upload-btn {
    max-width: 165px;
  }

  .centered-text {
    max-width: 131px;
  }

  .calender-row .upload-work .work-detail h3 {
    font-size: 16px;
  }

  .centered-text img {
    max-width: 139px;
  }

  .work-detail .work-Img img.plus-icon {
    width: 200px;
    height: 173px;
  }

  .work-detail .work-Img .picture-preview {
    width: 115px;
  }

  .contact-wrapper .home-container {
    margin-top: 0;
    padding: 0px 20px 30px;
  }

  .my-container {
    max-width: 100%;
  }

  .contact-wrapper .home-container .select {
    margin: 0 auto;
  }

  .g-map {
    margin-top: 4rem;
  }

  .navbar .select-btn .myInputRow {
    height: 46px;
  }

  .navbar .select-btn .form-select {
    height: 46px;
  }

  .detail-table thead tr {
    height: 90px;
  }

  .detail-table td p {
    font-size: 17px;
  }

  .detail-table tbody tr {
    padding: 0;
    height: 80px;
  }

  .detail-table th span {
    font-size: 20px;
    line-height: 30px;
  }

  .detail-table th,
  .detail-table td {
    padding: 0;
  }

  .detail-table div.my-pagination a.page-link {
    height: 40px;
    width: 40px;
  }

  .detail-table div.my-pagination li.page-item.disabled a.page-link:before,
  .detail-table div.my-pagination a.page-link:before {
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .op-select {
    min-width: auto;
    width: 126px;
  }

  .op-select .css-1okebmr-indicatorSeparator::before {
    height: 14%;
    left: auto;
    position: absolute;
    width: 11%;
    top: 17px;
    right: 6px;
  }

  .op-select .css-1s2u09g-control,
  .css-1pahdxg-control {
    height: 40px;
  }

  .op-select .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding-right: 0px !important;
    width: 0.1px;
  }

  .css-14el2xx-placeholder {
    font-size: 16px;
  }

  .css-319lph-ValueContainer::before {
    left: 5px;
    width: 11px;
  }

  .op-select .css-qc6sy-singleValue {
    font-size: 14.5px;
  }

  .paginate-button {
    padding: 20px 15px;
  }

  .css-14el2xx-placeholder::before {
    width: 11% !important;
  }

  .modal-close {
    width: 30px;
  }

  .ReactModal__Content {
    border-radius: 15px !important;
  }

  .slider {
    padding: 20px 0 0;
  }

  .exported-detail {
    padding: 10px;
  }

  .my-btn.slider-btn {
    justify-content: space-evenly;
  }

  .slider-btn.my-btn .btn-property {
    margin: 0 0 10px 0;
    max-width: 128px;
  }

  .slider .slide img {
    width: 235px;
  }

  .slider .mybox {
    width: 215px;
  }

  .slick-dots li {
    margin: 0 2px;
  }

  .slick-dots li button {
    width: 15px;
    height: 15px;
    margin: 0;
  }

  .slider .slick-dots li.slick-active button:before {
    opacity: 1;
    font-size: 5px;
    line-height: 14px;
    left: 41%;
    color: #ffbc00;
    top: 55%;
    transform: translate(-50%, -50%);
  }

  .exported-detail table tr td {
    font-size: 13px;
    line-height: 23px;
  }

  .map-modal .select {
    margin: 20px 0 0px;
    font-size: 14px;
  }

  .date-picker .react-datepicker-popper {
    padding: 10px;
  }

  .date-picker .react-datepicker {
    padding: 70px 5px 20px;
  }

  .date-picker .react-datepicker::before {
    font-size: 20px;
    top: 16px;
    left: 32px;
  }

  .date-picker .react-datepicker::after {
    height: 30px;
    width: 6px;
    left: 10px;
    top: 16.3px;
  }

  .date-picker .react-datepicker__day-name,
  .react-datepicker__day,
  .date-picker .react-datepicker__time-name {
    margin: 0 !important;
  }

  .date-picker .react-datepicker__day-name {
    font-size: 17px;
  }

  .date-picker .react-datepicker__day {
    font-size: 14px;
  }

  .react-datepicker .react-datepicker__navigation--next {
    right: -2px;
  }

  .react-datepicker__navigation.react-datepicker__navigation--previous {
    left: -2px;
  }

  .btn-property.pdf {
    padding: 0;
  }

  #password-modal,
  #clock-out-property,
  #clock-in-property,
  #trackerModal {
    max-width: 90%;
  }

  #upload-out-modal {
    max-width: 85%;
  }
}

@media screen and (max-width: 500px) {
  .work-detail h3 span {
    font-size: 9px;
  }

  .navbar-toggler-icon {
    width: 100%;
    height: 100%;
  }

  .navbar-toggler {
    width: 40px;
    height: 40px;
    padding: 0 5px;
  }

  .profile-pic {
    width: 45px !important;
    height: 45px !important;
  }

  .contact-wrapper .login-form.edit-row {
    padding: 20px 20px 45px;
  }

  .contact-wrapper .edit-row h2 {
    font-size: 33px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .btn-property {
    margin-right: 0;
  }

  .employee-profile {
    /* padding-top: 70px; */
  }

  .clock-time {
    margin-top: 4rem;
  }

  .centered-text {
    max-width: 121px;
  }

  .upload-work h3 {
    margin-left: -17px;
  }

  .centered-text img {
    max-width: 128px;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 469px;
  }

  .upload-btn {
    max-width: 154px;
  }

  .calender-row .upload-work .work-detail h3 {
    font-size: 13.6px;
  }

  .css-14el2xx-placeholder {
    font-size: 12.4px;
  }

  .my-btn .btn-property {
    max-width: 172px;
    font-size: 16px;
    height: 44px;
  }

  .nav-logo {
    height: 63px;
  }
}

@media screen and (max-width: 455px) {
  .home-container {
    max-width: 82.91%;
  }

  .upload-y.upload-work,
  .upload-y {
    border-radius: 13px;
  }

  .priview-y p {
    font-size: 13px;
    margin: 0 10px;
  }

  .sell-property-image-y {
    height: 80px;
    width: 80px;
  }

  .up-btn .select {
    max-width: 113px;
    font-size: 15px;
    height: 40px;
  }

  .upload-work,
  .work-detail {
    /* flex-direction: column;
  justify-content: center;
  align-items: center; */
  }

  .image-file {
    line-height: 58px;
  }

  .work-detail .work-Img img.plus-icon,
  .work-detail .work-Img {
    margin-right: 0;
    margin-left: 0;
  }

  .work-detail h3 span {
    display: none;
  }

  .upload-y.upload-work,
  .upload-y {
    padding: 7px 7px;
  }

  .upload-work h3 {
    margin: 0;
    padding: 5px 0;
    text-align: center;
  }

  .navbar .select-btn {
    display: none !important;
  }

  .calender {
    max-width: 412px;
    padding: 0px 5px 10px;
  }

  .calender h3 {
    margin-bottom: 69px;
  }

  .react-calendar__navigation__label__labelText {
    right: -23px;
    font-size: 14.5px;
    /* max-width: 233px; */
    top: 68px;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 412px;
  }

  .calender-row .upload-work .work-detail h3 {
    font-size: 14px;
  }

  /* .centered-text {
  max-width: 112px;
}
.centered-text img {
  max-width: 129px;
} */
  .calender .plus {
    right: 172px;
    top: 74px;
  }

  .calender .down-arrow {
    top: 89px;
    right: 25px;
  }

  .nav-pills .nav-link {
    font-size: 20px;
    padding: 16px 0;
  }

  .manager-profile .employee-name h3 {
    margin-top: 1rem;
  }

  .manager-profile .employee-name h3:nth-child(2) {
    margin-top: 0px;
  }
}

@media screen and (max-width: 414px) {
  .profile-img {
    width: 130px;
    height: 130px;
  }

  .manager-profile .employee-name h3 {
    padding-left: 10px;
    font-size: 21px;
  }

  /* .ReactModal__Overlay{
    margin: 0 12px;
  } */

  .ReactModal__Content {
    /* max-height:; */
    width: 100%;
    max-width: 95%;
    margin-left: -2rem;
  }

  .modal .ReactModal__Content {
    margin: 0 auto;
    width: 100%;
  }

  /* .main-header .navbar a.navbar-brand{
    margin: auto;
  } */
  .manager-profile .employee-id h3 {
    margin-top: 5px;
  }

  .calender h3 {
    padding-left: 33px;
  }

  .nav-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-calendar__navigation__label__labelText {
    right: -23px;
    /* max-width: 232px; */
  }

  .attend-calender::before {
    left: 7px;
  }

  .calender {
    max-width: 367px;
    padding: 0px 5px 10px;
  }

  abbr[title] {
    font-size: 14px;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0;
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 0px !important;
  }

  .react-calendar__tile {
    height: 50px;
  }

  .react-calendar__tile abbr {
    font-size: 12px;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 369px;
  }

  .react-calendar__tile--now.react-calendar__month-view__days__day abbr,
  .react-calendar__tile--active.react-calendar__month-view__days__day abbr,
  .react-calendar__tile:enabled:hover.react-calendar__month-view__days__day abbr {
    font-size: 11px;
    height: 35px;
    width: 35px;
    line-height: 35px;
    color: #fff !important;
  }

  h1 {
    font-size: 52px;
  }

  .sell-property-image-y {
    height: 65px;
    width: 65px;
  }

  .up-btn .select {
    max-width: 100px;
    font-size: 14px;
    height: 35px;
  }

  .priview-y p {
    font-size: 11px;
  }
}

@media screen and (max-width: 375px) {
  .my-btn .btn-property {
    max-width: 157px;
    font-size: 14px;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 328px;
  }

  .op-select {
    margin-right: 8px;
  }

  .op-select {
    width: 100px;
  }

  .op-select .css-qc6sy-singleValue {
    font-size: 12px;
    padding: 0;
  }

  .op-select .css-1s2u09g-control,
  .css-1pahdxg-control {
    height: 30px;
  }

  .op-select .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding-right: 0px !important;
  }

  .pagination li:first-child {
    margin-right: 10px;
  }

  .react-calendar__tile abbr.pending,
  .react-calendar__tile abbr.success {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

@media screen and (max-width: 340px) {
  .my-btn .btn-property {
    max-width: 145px;
  }

  .calender-row .upload-work,
  .upload-work,
  .calender,
  .upload-y {
    max-width: 300px;
  }

  .op-select {
    width: 99px;
  }

  .react-calendar__tile abbr {
    font-size: 10.5px;
  }

  .react-calendar__tile abbr.pending,
  .react-calendar__tile abbr.success {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

@media screen and (max-width: 320px) {
  .my-btn .btn-property {
    max-width: 70%;
  }

  .my-btn {
    flex-direction: column;
  }

  .main-header .container-fluid {
    justify-content: center;
  }

  .main-header .navbar-brand {
    margin: 1px;
  }
}