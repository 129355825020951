

@font-face {
    font-family: 'Helvetica bold';
    src: url('Helvetica-Bold.woff2') format('woff2'),
        url('Helvetica-Bold.woff') format('woff');
    font-weight: bold;
    font-style: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('Helvetica.woff2') format('woff2'),
        url('Helvetica.woff') format('woff');
    font-weight: normal;
    font-style:oblique;
    font-display: swap;
}

/* 

@font-face {
    font-family: 'Helvetica';
    src: url('Helvetica-Oblique.woff2') format('woff2'),
        url('Helvetica-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
} */

